import { MenuDivider, MenuItem } from '@pelckmans/business-components/components/action-menu';
import { MaterialShareModal, modalId } from '@pelckmans/business-components/components/material-share-modal';
import ArrowUpRightFromSquareIcon from '@pelckmans/business-components/icons/ArrowUpRightFromSquare';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import DuplicateIcon from '@pelckmans/business-components/icons/Duplicate';
import PencilWithSquareIcon from '@pelckmans/business-components/icons/PencilWithSquare';
import ShareIcon from '@pelckmans/business-components/icons/Share';
import SlideOverviewIcon from '@pelckmans/business-components/icons/SlideOverview';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import SlideSetUserIcon from '@pelckmans/business-components/icons/SlideSetUser';
import { number, oneOf, shape, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { errorOccurred } from '../../../../../../actions/error';
import { deleteUserSlideSet, shareSlideSet } from '../../../../../../actions/slideSets';
import { STUDIO_GROUP } from '../../../../../../constants/constants';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import FeatureToggle from '../../../../../../enums/feature-toggle';
import SlideSetStates from '../../../../../../enums/slideSetState';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getModuleForDigibook } from '../../../../../../selectors/digibooks';
import { isFeatureEnabled } from '../../../../../../selectors/featureToggle';
import { tokenGetter } from '../../../../../../services/api';
import { buildDoceoCopyUrl, buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import Confirmation from '../../../dialogs/confirmation';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';
import useOptionsMenuActionsTranslations from './hooks/useOptionsMenuActionsTranslations';

const ModalTypes = {
  SHARE: 'share',
  CONFIRM: 'confirm',
};

export default function OptionsMenuActions({ slideSet }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { name, id } = slideSet;
  const [visibleModalType, setVisibleModalType] = useState(undefined);
  const analytics = useAnalytics();
  const module = useSelector(getModuleForDigibook);

  const shareSlideSetEnabled = useSelector(state => isFeatureEnabled(state, FeatureToggle.SHARE_SLIDE_SET));

  const closeModal = () => setVisibleModalType(undefined);

  const deleteUserSlideSetAndCloseDrawer = useCloseDrawerOnMobile(() => {
    console.log('trying to delete user slide set');
    dispatch(deleteUserSlideSet(slideSet));
    closeModal();
  });

  const closeDrawer = useCloseDrawerOnMobile();

  const logPresentEventAndCloseDrawer = useCloseDrawerOnMobile(() => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: module.id,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  });

  const logEditModeEventAndCloseDrawer = useCloseDrawerOnMobile(() => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_EDIT_MODE_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  });

  const logOverviewEventAndCloseDrawer = useCloseDrawerOnMobile(() => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_OVERVIEW_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  });

  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);
  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const handleSlideSetShare = useCallback(
    async shareInfo => {
      await dispatch(shareSlideSet(slideSet.versionId, shareInfo));
      closeModal();
    },
    [dispatch, slideSet],
  );

  const materialShareModalTranslations = useOptionsMenuActionsTranslations(name);

  const { editEnabled, sharingEnabled } = useMemo(() => {
    const isLicensed = module.licenses.hasDigitalManualLicenseForTeacher;

    return {
      editEnabled: isLicensed,
      sharingEnabled: (isLicensed || slideSet.shared) && slideSet.state === 'PUBLISHED',
    };
  }, [module, slideSet]);

  const duplicateEnabled = slideSet.state === 'PUBLISHED' && editEnabled;

  const openShareModalAndCloseDrawer = useCloseDrawerOnMobile(() => setVisibleModalType(ModalTypes.SHARE));

  return (
    <>
      <MenuItem
        href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS)}
        onClick={logPresentEventAndCloseDrawer}
        target="_blank"
        rel="noopener noreferrer"
        title={`${name} ${t('drawer.slideSets.actionMenu.present').toLowerCase()}`}
        role="link"
      >
        <SlideSetIcon />
        <span>{t('drawer.slideSets.actionMenu.present')}</span>
        <ArrowUpRightFromSquareIcon style={{ marginLeft: 40 }} />
      </MenuItem>
      <MenuDivider />
      <MenuItem
        href={buildDoceoUrl(slideSet, false)}
        target="_blank"
        rel="noopener noreferrer"
        title={`${name} ${t('drawer.slideSets.actionMenu.overview').toLowerCase()}`}
        onClick={logOverviewEventAndCloseDrawer}
        role="link"
        disabled={!editEnabled}
      >
        <SlideOverviewIcon />
        <span>{t('drawer.slideSets.actionMenu.overview')}</span>
      </MenuItem>
      {slideSet.userId && (
        <>
          <MenuItem
            href={buildDoceoUrl(slideSet, true)}
            onClick={logEditModeEventAndCloseDrawer}
            target="_blank"
            rel="noopener noreferrer"
            title={`${name} ${t('drawer.slideSets.actionMenu.edit').toLowerCase()}`}
            role="link"
            disabled={!editEnabled}
          >
            <PencilWithSquareIcon />
            <span>{t('drawer.slideSets.actionMenu.edit')}</span>
          </MenuItem>
          <MenuItem role="button" onClick={() => setVisibleModalType(ModalTypes.CONFIRM)} title={`${name} ${t('actions.delete').toLowerCase()}`}>
            <BinIcon />
            <span>{t('actions.delete')}</span>
          </MenuItem>
        </>
      )}
      <MenuItem
        href={buildDoceoCopyUrl(slideSet.id, slideSet.versionId, slideSet.moduleId, slideSet.nodeId, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS)}
        onClick={closeDrawer}
        target="_blank"
        rel="noopener noreferrer"
        title={duplicateEnabled ? `${name} ${t('drawer.slideSets.actionMenu.duplicate').toLowerCase()}` : ''}
        disabled={!duplicateEnabled}
      >
        <DuplicateIcon />
        <span>{t('drawer.slideSets.actionMenu.duplicate')}</span>
      </MenuItem>
      {shareSlideSetEnabled && !!slideSet.userId && (
        <>
          <MenuDivider />
          <MenuItem onClick={openShareModalAndCloseDrawer} title={`${name} ${t('drawer.slideSets.actionMenu.share').toLowerCase()}`} role="button" disabled={!sharingEnabled}>
            <ShareIcon />
            {t('drawer.slideSets.actionMenu.share')}
          </MenuItem>
        </>
      )}
      {visibleModalType === ModalTypes.CONFIRM && (
        <Confirmation
          title={`${t('actions.delete')} ${name}`}
          icon={<BinIcon />}
          message={t('drawer.slideSets.actionMenu.deleteMessage')}
          confirmationText={t('actions.delete')}
          confirmationClassName="pbb-btn--danger"
          cancellationText={t('confirmation.cancel')}
          onConfirm={deleteUserSlideSetAndCloseDrawer}
          onCancel={closeModal}
        />
      )}

      {visibleModalType === ModalTypes.SHARE &&
        createPortal(
          <MaterialShareModal
            id={modalId}
            headerStartAdornment={slideSet.userId ? <SlideSetUserIcon /> : <SlideSetIcon />}
            material={slideSet}
            materialType="slide-set"
            studioGroup={STUDIO_GROUP}
            tokenAccessor={tokenAccessor}
            language={language}
            translations={materialShareModalTranslations}
            onSave={handleSlideSetShare}
            onClose={() => setVisibleModalType(undefined)}
            onError={handleError}
          />,
          document.body,
        )}
    </>
  );
}

OptionsMenuActions.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    versionId: number.isRequired,
    state: oneOf(Object.values(SlideSetStates)),
  }).isRequired,
};
