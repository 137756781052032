import update from 'immutability-helper';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { SHARED_SLIDE_SETS_FETCH_SUCCESS, SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS, SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS, SLIDE_SETS_SHARE } from '../actions/actionNames';

export const initialState = {
  byVersionId: {},
  byTocNodeId: {},
};

export default function slideSetsReducer(state = initialState, action) {
  switch (action?.type) {
    case SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS: {
      const { nodeId, slideSets } = action.payload;

      const keyByVersionId = keyBy(slideSets, 'versionId');

      return update(state, {
        byVersionId: { $merge: keyByVersionId || {} },
        byTocNodeId: { [nodeId]: { $set: slideSets.map(slide => slide.versionId.toString()) } },
      });
    }
    case SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS: {
      const { slideSet } = action.payload;

      console.log('slideSetReducer', slideSet);

      return update(state, {
        byVersionId: { $unset: [slideSet.versionId] },
        byTocNodeId: {
          [slideSet.nodeId]: {
            $apply: tocNodeIds => tocNodeIds.filter(id => id !== String(slideSet.versionId)),
          },
        },
      });
    }
    case SLIDE_SETS_SHARE: {
      const { slideSetVersionId, shares } = action.payload;

      return update(state, {
        byVersionId: {
          [slideSetVersionId]: {
            shared: { $set: Boolean(shares) },
            $merge: { shares },
          },
        },
      });
    }
    case SHARED_SLIDE_SETS_FETCH_SUCCESS: {
      const { slideSets } = action.payload;

      const slideSetsByVersionId = keyBy(
        slideSets.map(slideSet => ({ ...slideSet, shared: !!slideSet.shares.length, userId: slideSet.owner?.id })),
        'versionId',
      );

      const slideSetsIdsByNodeId = Object.entries(groupBy(slideSets, 'nodeId')).reduce((acc, entry) => {
        const [nodeId, ss] = entry;

        acc[nodeId] = ss.map(slideSet => slideSet.versionId);
        return acc;
      }, {});

      return update(state, {
        byTocNodeId: { $set: slideSetsIdsByNodeId },
        byVersionId: { $set: slideSetsByVersionId },
      });
    }
    default: {
      return state;
    }
  }
}
